import Api from './api.service';

export default {
  get: (params) => Api.get('doctors', { params }),
  getId: (id) => Api.get(`doctors/${id}`),
  save: (data) => Api.post(`doctors/`, data),
  update: (id, data) => Api.put(`doctors/${id}`, data),
  delete: (id) => Api.delete(`doctors/${id}`),
  export: (filters) => Api.post('doctors/export', { filters }),
  patch: (id, data) => Api.patch(`doctors/${id}`, data),
  getDoctorTransactions(params = {}) {
    return Api.get('doctors-transactions', { params });
  },
  getBalanceInfo(id) {
    return Api.get(`doctors/balance-info?doctor_id=${id}`);
  },
  setPhoto: (doctorId, file, type, junoFile = 0) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);
    formData.append('juno_file', junoFile);

    return Api.post(`doctors/${doctorId}/set-photo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    });
  },
  setPfxDocument(doctorId, file) {
    const formData = new FormData();

    formData.append('file', file);

    return Api.post(`doctors/${doctorId}/set-pfx`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    });
  },
};
