export default [
     {
        text: 'Ativa'
    },
     {
        text: 'Aguardando documentos'
    },
     {
        text: 'Verificando'
    },
     {
        text: 'Verificada'
    },
     {
        text: 'Bloqueada'
    },
     {
        text: 'Inativa'
    }
]
