<template>
  <div class="appointment-list-view">
    <app-header icon="user-md" title="Médicos"></app-header>
    <section>
      <div class="card">
        <header class="card-header">
          <div class="card-header-content">
            <div class="row filters">
              <div class="col-4">
                <app-input
                  v-model="searchTerm"
                  label="Filtrar por Médico(a)"
                  placeholder="Nome, e-mail ou CPF/CNPJ"
                  icon="search"
                >
                </app-input>
              </div>

              <div class="col-md-3">
                <b-field label="Filtrar por status">
                  <b-select
                    v-model="searchApproved"
                    placeholder="Selecione..."
                    expanded
                  >
                    <option :value="-1">Todos</option>
                    <option :value="1">Aprovado</option>
                    <option :value="0">Não Aprovado</option>
                  </b-select>
                </b-field>
              </div>
              <div class="col-md-3">
                <b-field label="Ativo/Inativo">
                  <b-select
                    v-model="searchActive"
                    placeholder="Selecione..."
                    expanded
                  >
                    <option :value="-1">Todos</option>
                    <option :value="1">Ativo</option>
                    <option :value="0">Inativo</option>
                  </b-select>
                </b-field>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-4">
                <b-field label="Filtrar cadastro - início">
                  <b-datepicker
                    v-model="searchDate"
                    :locale="'pt-BR'"
                    :years-range="[-100, 100]"
                    placeholder="Selecionar data..."
                    icon="calendar"
                    trap-focus
                  ></b-datepicker>
                </b-field>
              </div>
              <div class="col-md-4">
                <b-field label="Filtrar cadastro - fim">
                  <b-datepicker
                    :min-date="searchDate"
                    v-model="searchDateEnd"
                    :locale="'pt-BR'"
                    :years-range="[-100, 100]"
                    placeholder="Selecionar data..."
                    icon="calendar"
                    trap-focus
                  ></b-datepicker>
                </b-field>
              </div>

              <div class="col-md-3">
                <b-field label="Ordenar por">
                  <b-select v-model="sorter" expanded @input="onPageChange(1)">
                    <option value="name">Nome</option>
                    <option value="id">ID - menor pro maior</option>
                    <option value="-id">ID - maior pro menor</option>
                    <option value="-schedule_count">
                      Quantidade de consultas
                    </option>
                    <option value="-schedule_sum">
                      Valor total das consultas
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>
            <!-- <div class="row mt-2">
              <div class="col-md-4">
                <b-field label="Filtrar por Aplicativo">
                  <b-select
                    v-model="searchHasApp"
                    placeholder="Selecione..."
                    expanded
                  >
                    <option :value="0">Todos</option>
                    <option :value="1">Tem Aplicativo</option>
                    <option :value="-1">Não Tem Aplicativo</option>
                  </b-select>
                </b-field>
              </div>
            </div> -->
          </div>
          <div class="card-header-buttons">
            <b-tooltip label="Novo Médico">
              <router-link
                :to="{
                  name: 'doctors.save',
                }"
              >
                <b-button size="is-small" type="is-success" icon-right="plus"
                  >Novo</b-button
                >
              </router-link>
            </b-tooltip>
            <b-tooltip label="Exportar XLS">
              <b-button
                class="ml-1"
                @click="exportDoctors()"
                type="is-dark"
                size="is-small"
                icon-left="file-excel"
                :disabled="isExporting"
                :loading="isExporting"
              >
                Exportar
              </b-button>
            </b-tooltip>
          </div>
        </header>
        <div class="card-content custom-scroll">
          <hr class="separated-table" />
          <div class="row mt-4 mb-2">
            <div class="col-3">
              <b-field>
                <b-checkbox v-model="showAllColumns"
                  >Mostrar todas as colunas</b-checkbox
                >
              </b-field>
            </div>
          </div>

          <b-table
            @page-change="onPageChange"
            :data="data"
            :total="total"
            :per-page="20"
            :loading="isLoading"
            paginated
            backend-pagination
            aria-next-label="Próxima Página"
            aria-previous-label="Página Anterior"
            aria-page-label="Página"
            aria-current-label="Página Atual"
          >
            <b-table-column v-slot="props" field="name" label="ID">
              {{ props.row.id }}
            </b-table-column>
            <b-table-column v-slot="props" field="name" label="Nome">
              {{ props.row.name }}
            </b-table-column>
            <b-table-column v-slot="props" field="email" label="Email">
              {{ props.row.email }}
            </b-table-column>
            <!-- <b-table-column 
              v-slot="props" 
              :visible="showAllColumns"
              field="name" 
              label="Tem App"
            >
              <span v-if="props.row.has_app">
                Sim
              </span>
              <span v-else>
                Não
              </span>
            </b-table-column> -->
            <b-table-column
              v-slot="props"
              :visible="showAllColumns"
              field="status"
              label="Status Juno"
            >
              {{ hasJunoAccount(props.row.juno_account) }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              :visible="showAllColumns"
              field="name"
              label="Qtd. Consultas"
            >
              {{ props.row.schedule_count }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              :visible="showAllColumns"
              field="name"
              label="Valor R$"
            >
              R$
              {{
                parseFloat(props.row.schedule_sum)
                  .toFixed(2)
                  .replace(',', '.')
                  .replace('.', ',')
              }}
            </b-table-column>

            <b-table-column v-slot="props" label="Aprovado">
              <b-checkbox
                true-value="1"
                false-value="0"
                v-model="props.row.approved"
                :doctor_id="props.row.id"
                :value="props.row.approved"
                class="mr-4"
                @input="
                  approveDoctor(props.row.approved, props.row.id, props.row)
                "
              />
            </b-table-column>

            <b-table-column v-slot="props" label="Apenas Visualização">
              <b-checkbox
                @input="setReadOnly(props, $event)"
                v-model="props.row.read_only"
              />
            </b-table-column>

            <b-table-column v-slot="props" label="Ativo">
              <b-checkbox
                true-value="1"
                false-value="0"
                v-model="props.row.active"
                :doctor_id="props.row.id"
                :value="props.row.active"
                class="mr-4"
                @input="activeDoctor(props.row.active, props.row.id, props.row)"
              />
            </b-table-column>
            <b-table-column v-slot="props" label="Cadastro">
              {{ props.row.created_at | toDate }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              label="Opções"
              cell-class="width-100"
            >
              <router-link
                :to="{
                  name: 'doctors.save',
                  params: { id: props.row.id },
                }"
              >
                <b-tooltip label="Editar" class="m-1">
                  <b-button
                    type="is-secondary"
                    size="is-small"
                    icon-right="edit"
                  />
                </b-tooltip>
              </router-link>
              <b-tooltip
                :label="
                  props.row.second_stage
                    ? 'Não é possível deletar esse médico'
                    : 'Deletar'
                "
                position="is-left"
                class="m-1"
              >
                <b-button
                  @click="onClickDelete(props.row.id)"
                  :disabled="!!props.row.second_stage"
                  type="is-danger"
                  size="is-small"
                  icon-right="trash"
                />
              </b-tooltip>
            </b-table-column>
            <template slot="footer">
              <div>
                <div class="">
                  <b>Total de médicos cadastrados:</b> {{ total_doctors }}<br />
                  <b>Total de médicos na pesquisa:</b> {{ total }}<br />
                </div>
              </div>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="frown" size="is-large"></b-icon>
                  </p>
                  <p>Nenhum registro encontrado.</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DoctorsService from '@/services/doctors.service';
import JUNO_ACCOUNT_STATUS from '@/constants/juno-account-status.constant';
import debounce from 'lodash/debounce';
import * as moment from 'moment';

import appSettingsService from '../../services/app-setting.service';

export default {
  data: () => ({
    juno_status: JUNO_ACCOUNT_STATUS,
    showAllColumns: false,
    isLoading: true,
    isExporting: false,
    data: [],
    total: 0,
    total_online: 0,
    total_doctors: 0,
    page: 1,
    searchName: '',
    searchEmail: '',
    emails: ['jose', 'maior'],
    isStatic: false,
    classA: 'class-a',
    sorter: 'name',
    onlines: '',
    searchDate: null,
    searchDateEnd: null,
    searchTerm: null,
    searchApproved: -1,
    searchActive: -1,
    searchHasApp: 0,
  }),
  filters: {
    toDate(value) {
      if (value) {
        return moment(new Date(value)).format('DD/MM/YYYY');
      }
    },
  },
  watch: {
    searchTerm: debounce(function () {
      this.page = 1;
      this.load();
    }, 400),
    searchApproved() {
      this.page = 1;
      this.load();
    },
    searchActive() {
      this.page = 1;
      this.load();
    },
    searchHasApp() {
      this.page = 1;
      this.load();
    },
    searchDate() {
      this.page = 1;
      this.load();
    },
    searchDateEnd() {
      this.page = 1;
      this.load();
    },
    onlines() {
      this.page = 1;
      this.load();
    },
    sorter() {
      this.page = 1;
      this.load();
    },
  },
  methods: {
    approveDoctor(value, doctor_id, row) {
      this.$buefy.dialog.confirm({
        message: `Deseja realmente ${
          row.approved != 1 ? '<b>desaprovar</b>' : '<b>aprovar</b>'
        } esse médico?`,
        onCancel: () => (value == 1 ? (row.approved = 0) : (row.approved = 1)),
        onConfirm: () => {
          DoctorsService.patch(doctor_id, { approved: value })
            .then(() => {
              this.$buefy.snackbar.open('Médico atualizado com sucesso!');
            })
            .catch(({ response }) => {
              const { message, errors } = response;

              if (response.status === 422) {
                this.errors = errors;
                value == 1 ? (row.approved = 0) : (row.approved = 1);
              } else
                this.$buefy.snackbar.open(
                  message || 'Erro ao tentar a atualizar o Médico.',
                  'error'
                );
              value == 1 ? (row.approved = 0) : (row.approved = 1);
            })
            .finally(() => (this.isSaving = false));
        },
      });
    },
    setReadOnly(tableRow, value) {
      const { row: doctor, index } = tableRow;
      DoctorsService.patch(doctor.id, { read_only: value ? 1 : 0 })
        .then(() => this.$buefy.snackbar.open('Médico atualizado com sucesso.'))
        .catch(() => {
          this.$buefy.snackbar.open('Erro ao tentar atualizar médico.');

          this.$set(this.data[index], 'read_only', !value);
        });
    },
    activeDoctor(value, doctor_id, row) {
      DoctorsService.patch(doctor_id, { active: value })
        .then(() => {
          this.$buefy.snackbar.open('Médico atualizado com sucesso!');
        })
        .catch(({ response }) => {
          const { message, errors } = response;

          if (response.status === 422) {
            this.errors = errors;
            value == 1 ? (row.active = 0) : (row.active = 1);
          } else
            this.$buefy.snackbar.open(
              message || 'Erro ao tentar a atualizar o Médico.',
              'error'
            );
          value == 1 ? (row.active = 0) : (row.active = 1);
        })
        .finally(() => (this.isSaving = false));
    },
    hasJunoAccount(account) {
      if (!account) return '-';

      return this.juno_status[account.status].text;
    },
    getHasApp(doctor_id) {
      return appSettingsService.verifyDoctor(doctor_id) ? 'Sim' : 'Não';
    },
    load() {
      this.isLoading = true;

      const params = {
        page: this.page,
        ['filter[approved]']:
          this.searchApproved > -1 ? this.searchApproved : '',
      };

      if (this.searchActive > -1) {
        params['filter[active]'] =
          this.searchActive > -1 ? this.searchActive : '';
      }

      if (this.searchHasApp != 0) {
        params['has_app'] = this.searchHasApp != 0 ? this.searchHasApp : '';
      }

      if (this.searchTerm) {
        params['filter[name_email_or_document]'] = this.searchTerm;
      }

      if (this.searchDate) {
        params['filter[created_at_greater]'] = moment(this.searchDate).format(
          'YYYY-MM-DD'
        );
      }

      if (this.searchDateEnd) {
        params['filter[created_at_less]'] = moment(this.searchDateEnd).format(
          'YYYY-MM-DD'
        );
      }

      if (this.onlines) {
        params['onlines'] = this.onlines;
      }
      if (this.sorter) {
        params['sort'] = this.sorter;
      }

      DoctorsService.get(params)
        .then(({ data }) => {
          const {
            total,
            total_online,
            total_doctors,
            data: items,
            current_page,
          } = data;
          this.total = total;
          this.total_online = total_online;
          this.total_doctors = total_doctors;
          this.data = items;
          this.page = current_page;
        })
        .finally(() => setTimeout(() => (this.isLoading = false), 300));
    },
    onPageChange(page) {
      this.page = page;
      this.load();
    },
    onClickDelete(id) {
      this.$buefy.dialog.confirm({
        title: 'Excluindo Médico',
        message:
          'Tem certeza que deseja <b>excluir</b> este médico? Essa ação não pode ser desfeita.',
        cancelText: 'Cancelar',
        confirmText: 'Excluir Médico',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.delete(id),
      });
    },
    delete(id) {
      DoctorsService.delete(id)
        .then(() => {
          this.$buefy.snackbar.open('Médico excluído com sucesso.');
          this.load();
        })
        .catch(({ response }) => {
          if (response.status != 200) {
            this.$buefy.snackbar.open(
              response.message || 'Erro ao tentar excluir o Médico.',
              'error',
              4000
            );
          }
        });
    },
    exportDoctors() {
      this.isExporting = true;

      let filters = {
        term: this.searchTerm || '',
        approved: this.searchApproved > -1 ? this.searchApproved : '',
        created_at: this.searchDate || '',
        has_app: this.searchHasApp || '',
      };

      DoctorsService.export(filters)
        .then(({ data }) => {
          const fileName = data.split('/')[data.split('/').length - 1];
          const fileLink = document.createElement('a');

          fileLink.target = '_blank';
          fileLink.href = data;
          fileLink.setAttribute('download', fileName);
          fileLink.setAttribute('style', 'display:none;');

          document.body.appendChild(fileLink);
          fileLink.click();

          fileLink.remove();
        })
        .catch(({ response }) => {
          const message =
            response.data.message || 'Erro ao realizar exportação.';
          this.$buefy.snackbar.open(message);
        })
        .finally(() => (this.isExporting = false));
    },
  },
  mounted() {
    this.load();
  },
};
</script>
<style scoped>
.card-content {
  overflow: auto;
}

.filters {
  align-items: flex-end;
}
.separated-table {
  margin: 5px 0;
}
.text-center {
  text-align: center !important;
}
</style>
